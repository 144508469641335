import React from "react";
import DataTable from "react-data-table-component";
import BackArraw from "../../Dashboard/image/arrow.png"


const BillingHistory = () => {

    const data = [
        {
          title: "Company Name",
          status: "Enable",
          postDate: "20/03/2025",
          expiringDate: "25/03/2025",
          views: "2",
          applies: "4",
        },
      ];

      const columns = [
        {
          name: "Job Title",
          selector: (row) => row.title,
          sortable: true,
          style: { fontFamily: "Lato", fontSize: "18px" },
        },
        {
          name: "Job Status",
          selector: (row) => row.status,
          sortable: true,
          style: { fontFamily: "Lato", fontSize: "18px" },
        },
        {
          name: "Post Date",
          selector: (row) => row.postDate,
          sortable: true,
          style: { fontFamily: "Lato", fontSize: "18px" },
        },
        {
          name: "Expiring Date",
          selector: (row) => row.expiringDate,
          sortable: true,
          style: { fontFamily: "Lato", fontSize: "18px" },
        },
        {
          name: "View",
          selector: (row) => row.views,
          sortable: true,
          style: { fontFamily: "Lato", fontSize: "18px" },
        },
        {
          name: "Applies",
          selector: (row) => row.applies,
          sortable: true,
          style: { fontFamily: "Lato", fontSize: "18px" },
        },
        {
          name: "Actions",
          cell: (row) => (
            <button
              className="btn btn-danger btn-sm"
              //   onClick={() => handleDelete(row.id)}
            >
              Delete
            </button>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
      ];

      const handleImageClick = () => {
        window.history.back();
      };
    
    return(
        <div className="container mt-4">
        <img src={BackArraw} style={{height: "30px", width: '30px', marginBottom: '10px', cursor:'pointer'}} onClick={handleImageClick} />
          <div className="card shadow-lg">
            <div
              className="card-header text-white"
              style={{ backgroundColor: "#ffd42f" }}
            >
              <h4 className="mb-0">Billing History</h4>
            </div>
            <div className="card-body">
              <DataTable
                columns={columns}
                data={data}
                pagination
                responsive
                className="table-striped table-hover"
              />
            </div>
          </div>
        </div>
    )
}

export default BillingHistory