/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";
import React, { useState, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import Footer from "../../components/footer";
import { toast } from "react-toastify";
import { UserIdContext } from "../../contexts/auth-context";
import { APIConstants } from "../../utils/config";
import { PostApiCall, GetApiCall } from "../../utils/axios";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

const CandidatesPage = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useState(null);
  const userIdContext = useContext(UserIdContext);
  const [checked, setChecked] = useState(false);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [userData, setUserData] = useState([])

  const handleChange = (e) => {
    const searchParam = {
      ...searchParams,
      [e.target.name]: e.target.value,
    };
    setSearchParams(searchParam);
  };

  useEffect(() => {
    GetApiCall(APIConstants.VIEWPROFILE_URL).then((response) => {
      setUserData(response.careeralert)
    })
  }, [])

  useEffect(() => {
    if (userData?.some((job) => job?.job_title === searchParams?.job_title)) {
      setShowCheckbox(false);
    } else {
      setShowCheckbox(true);
    }
  }, [userData, searchParams]);

  const handleCheckboxChange = async (event) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);

    if (isChecked) {
      try {
        const resetTokenBody = {
          job_title: searchParams?.job_title,
          location: searchParams?.location,
        };
        PostApiCall(APIConstants.CAREER_ALEART, resetTokenBody).then(
          (response) => {
            if (response.status) {
              setShowCheckbox(false); // Hide checkbox permanently
            }
          }
        );
      } catch (error) {
        console.error("API Error:", error);
      }
    }
  };

  const nextHandler = (e) => {
    e.preventDefault();
    if (searchParams !== null) {
      if (
        searchParams["job_title"] !== undefined &&
        searchParams["location"] !== undefined
      ) {
        navigate({
          pathname: "/jobs-search",
          search: `?job_title=${searchParams.job_title}&location=${searchParams.location}`,
        });
      } else if (searchParams["location"] !== undefined) {
        navigate({
          pathname: "/jobs-search",
          search: "?location=" + searchParams.location,
        });
      } else if (searchParams["job_title"] !== undefined) {
        navigate({
          pathname: "/jobs-search",
          search: "?job_title=" + searchParams.job_title,
        });
      }
    } else {
      toast("Please enter your desired job/job location");
    }
  };

  return (
    <>
      <div className="candidate-outer">
        <div className="container-cstm">
          <div className="content-section">
            <div className="cntnt-left">
              <h2>Your Future Career Starts Here.</h2>
              <form style={{bottom: '4px'}}>
                <div className="frm-lft">
                  <label>Job Title/Company</label>
                  <div className="txt-otr">
                    <i className="fa fa-search" aria-hidden="true"></i>
                    <input
                      type="text"
                      name="job_title"
                      placeholder="Ex. HR Manager or Volunteer"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="frm-rgt">
                  <label>Location</label>
                  <div className="txt-otr">
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    <input
                      type="text"
                      placeholder="Ex. Toronto or Ontario"
                      name="location"
                      onChange={handleChange}
                    />
                  </div>
                  {/* <GooglePlacesAutocomplete apiKey="AIzaSyBTqxUSWMpqy4-g_2-vkGkZpq7qh2RoLyA" /> */}
                </div>
                <div onClick={(e) => nextHandler(e)}>
                  <input
                    type="submit"
                    className="btn fa-input"
                    value="Search   &#xf061; "
                  />
                </div>
              </form>
              {/*<ul className="f-catg">*/}
              {/*  <li>*/}
              {/*    Following Categories: <a href="#">Urgent Roles</a>,{" "}*/}
              {/*    <a href="#">STEM roles</a>,{" "}*/}
              {/*    <a href="#">Volunteer positions</a>*/}
              {/*  </li>*/}
              {/*</ul>*/}
            </div>
            <div className="cntnt-right">
              <img
                src={require("../../assets/images/pexels-olly-840996.jpg")}
                alt=""
              />
            </div>
          </div>
          {searchParams?.job_title &&
          searchParams?.location &&
          userIdContext == null ? (
            <h5 className="pb-2" style={{ fontSize: "20px", color: 'blue' }}>
              Please login to create Career Alert for this job
            </h5>
          ) : searchParams?.job_title &&
            searchParams?.location &&
            userIdContext !== null &&
            showCheckbox ? (
            <label className="pb-2" style={{ fontSize: "20px" }}>
              <input
                type="checkbox"
                style={{ marginRight: "5px" }}
                onChange={handleCheckboxChange}
              />{" "}
              Create Job Alert
            </label>
          ) : null}
        </div>
        <Footer />
      </div>
    </>
  );
};
export default CandidatesPage;
