/* eslint-disable jsx-a11y/anchor-is-valid */

import React, {useContext, useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {APIConstants} from "../../../utils/config";
import {GetApiCall, PostApiCall} from "../../../utils/axios";
import Loader from "../../../components/loader";
import {RoleTypeContext, UserIdContext} from "../../../contexts/auth-context";
import {toast} from "react-toastify";
import moment from "moment/moment";
import Footer from "../../../components/footer";
import axios from "axios";
import "./index.css"

const JobDescriptionPage = () => {
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [previewResponse, setPreviewResponse] = useState({});
    const [savedJobs, setSavedJobs] = useState(); // Store saved jobs state
    const roleTypeContext = useContext(RoleTypeContext);
    const userIdContext = useContext(UserIdContext);

    const myjobDetails = () => {
        setLoading(true);
        GetApiCall(APIConstants.JOBPREVIEW_URL + searchParams.get("id")).then(
            (data) => {
                setPreviewResponse(data.data);
                setLoading(false);
            }
        );
    };
    const SavedJobs = () => {
        PostApiCall(APIConstants.SAVEJOB_URL + searchParams.get("id")).then(
            (data) => {
                if (data.status) {
                    setPreviewResponse({...previewResponse, isSaved: !previewResponse.isSaved});
                    toast(data.message);
                } else {
                    toast("You have already saved this job");
                }
            }
        );
    };
    const ApplyJobs = () => {
        PostApiCall(APIConstants.APPLYJOB_URL + searchParams.get("id")).then(
            (data) => {
                setPreviewResponse({...previewResponse, isApplied: true});
                toast(data.message);
            }
        );
    };

    const saveJobHandles = async (e, listing) => {
        e.stopPropagation();
    
        try {
          const response = await axios.post(
            `https://api.hireboss.ca/api/job/save/${listing.id}`, // Assuming job_id is needed,
          );
    
          if (response.status == true) {
            setSavedJobs(true); // Mark job as saved
          }
        } catch (error) {
          console.error("Error saving job:", error.response?.data || error.message);
        }
      };

    const appliedButton = () => {
        ApplyJobs();
    };
    useEffect(() => {
        myjobDetails();
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat("en-GB", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        }).format(date);
      };


    return (
        <div>
            {loading && <Loader loading={loading}/>}
            <section class="container-fluid job-listing-area">
            <div class="container">
            <div class="row g-4">
            <div class="col-lg-12 col-md-12 job-description">
            <div class="card">
                        <div class="card-body">
                            {/* <div class="logo" style={{width: '35%', height: '95px'}}>
                            <span>{previewResponse?.title?.[0]}</span>
                            </div> */}
                            <div class="content">
                                <div class="job-header">
                                    <h5>{previewResponse?.title}</h5>
                                    <h3 style={{fontSize: '18px'}}>{previewResponse?.company}</h3>
                                    {
                                        previewResponse?.min_salary ? (
                                            <p class="company-name">
                                        <span>Salary</span>
                                        <span>-</span>
                                        <span class="job-count">${previewResponse?.min_salary} - ${previewResponse?.max_salary}</span>
                                    </p>
                                        ) : null
                                    }
                                    <p class="area">{previewResponse?.job_location}</p>
                                    <div class="button-group">
                                        <button class="btn btn-apply" onClick={(e) => appliedButton()}>{previewResponse?.is_applied > 0 ? "Applied" : "Apply"}</button>
                                        <button class="btn btn-save" onClick={(e) => saveJobHandles(e, previewResponse)}>
                                            <i class="fa fa-heart"></i>
                                            <span>{savedJobs == true ? "Saved" : "Save"}</span>
                                        </button>
                                    </div>
                                </div>
                                <div class="job-desc" style={{padding: "10px 0px 0px 4px"}}>
                                    <div class="description-header">
                                        <h3>Job Details:</h3>
                                        <ul class="keywords">
                                            <li>{previewResponse?.job_type
                                        ? previewResponse?.job_type
                                        : previewResponse?.job_type == ""
                                        ? "Full Time"
                                        : previewResponse?.job_type}</li>
                                        </ul>
                                        {/* <h3>Benefits:</h3> */}
                                        {/* <p class="job-type mb-3">
                                        <i class="fa fa-briefcase" aria-hidden="true"></i>
                                        <span>flexible work</span>
                                    </p> */}
                                    </div>
                                    <div class="job-desc-detailed">
                                        <p  dangerouslySetInnerHTML={{
                                      __html: previewResponse?.description,
                                    }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            </div>
            </div>
            </section>

            {/* <div className="grey-bg">
                <div className="candidate-outer ">
                    <div className="container-cstm">
                        <div className="content-inner">
                            <div className="cmp-desg-apply-section">
                                <div className="cmp-desg">
                                    <img
                                        src={previewResponse?.employer_profile?.logo}
                                    />
                                    <div className="desg-sec">
                                        <p>{previewResponse?.title}</p>
                                        <p>
                      <span>
                        {" "}
                          {previewResponse?.employer_profile?.company_name}.{previewResponse?.city} , {previewResponse?.state},{" "}
                          {previewResponse?.country}.{" "}
                          {previewResponse?.job_type?.name}
                      </span>
                                        </p>
                                        <p>{moment(previewResponse.created_at).format("MM-DD-YYYY")}</p>
                                    </div>
                                </div>
                                {userIdContext !== null && roleTypeContext !== "Employer" && (
                                    <div className="apply-section">
                                        <p className="bookmark" onClick={(e) => SavedJobs()}>
                                            Save this job{" "}
                                            <a
                                                href="#"
                                                className={`${previewResponse.isSaved && "active"}`}
                                            >
                                                <i class="fa fa-bookmark-o" aria-hidden="true"></i>
                                                <i class="fa fa-bookmark" aria-hidden="true"></i>
                                            </a>
                                        </p>
                                        <p className="apply-btn" onClick={(e) => appliedButton()}>
                                            <a href="#">
                                                {" "}
                                                {previewResponse?.isApplied ? "Applied" : "Apply Now"}
                                                {!previewResponse?.isApplied && (
                                                    <i
                                                        className="fa fa-arrow-right"
                                                        aria-hidden="true"
                                                    ></i>
                                                )}
                                            </a>
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="salary-rate">
                                <p>
                                    Salary:{" "}
                                    <span>{previewResponse?.average_salary} /annually</span>
                                </p>
                            </div>
                            <div className="job-desc">
                                <p>
                                    <strong> Essential Duties & Responsibilities </strong>
                                </p>
                                <ul>
                                    <li
                                        dangerouslySetInnerHTML={{
                                            __html: previewResponse?.duties_and_responsibilty,
                                        }}
                                    ></li>
                                </ul>

                                <p>
                                    <strong> Qualifications and Education Requirements </strong>
                                </p>
                                <ul>
                                    <li
                                        dangerouslySetInnerHTML={{
                                            __html: previewResponse?.requirements,
                                        }}
                                    ></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <Footer/>
        </div>
    );
};
export default JobDescriptionPage;
