/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { jobListApi } from "../../../redux/candidates/jobs-listing/jobs-list-saga";
import FilterMenu from "../../../components/filterMenu";
import Loader from "../../../components/loader";
import axios from "axios";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { PostApiCall } from "../../../utils/axios";
import { APIConstants } from "../../../utils/config";
import { toast } from "react-toastify";
import Pagination from "../../../components/pagination";
import { RoleTypeContext, UserIdContext } from "../../../contexts/auth-context";
import NoResult from "../../../components/no-result";
import moment from "moment";
import { JobsListActionTypes } from "../../../redux/candidates/jobs-listing/jobs-list-enum";
import Footer from "../../../components/footer";
import "./index.css";
import FilterIcon from "../../../assets/images/filter.svg";

// const data = {
//   10 : {companyname: 'testing', id: '34343'},
//   11 : {companyname: 'fixing', id: '34453343'},
//   12 : {companyname: 'journy', id: '3343444'},
// }

const JobPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const jobsListState = useSelector((state) => state.jobsListState);
  const userIdContext = useContext(UserIdContext);
  const roleTypeContext = useContext(RoleTypeContext);
  const [savedJobs, setSavedJobs] = useState({}); // Store saved jobs state
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [JobSearchParams, setJobSearchParams] = useState(null);
  const [searchParam] = useSearchParams();
  const selectedJobType = searchParam.get("location_type") || "";
  const selectedCategory = searchParam.get("category") || ""; // Changed to "category"
  const selectedLocation = searchParams.get("location") || "";

  useEffect(() => {
    dispatch(jobListApi(location.search));
  }, [searchParams]);

  useEffect(() => {
    if (location.search !== "") {
      dispatch(jobListApi(location.search));
    } else {
      dispatch(jobListApi(""));
    }
  }, []);

  const applyHandler = (e, job) => {
    e.preventDefault();
    navigate({
      pathname: "/job-description",
      search: createSearchParams({
        id: job.id,
      }).toString(),
    });
  };

  const ApplyJobs = (jobApplyId) => {
    PostApiCall(APIConstants.APPLYJOB_URL + jobApplyId).then((data) => {
      let updateJobListing = jobsListState.jobsListing;
      const singleJob = updateJobListing.data.findIndex(
        (joblistingId) => joblistingId.id === jobApplyId
      );
      if (singleJob !== -1) {
        updateJobListing.data[singleJob].isApplied =
          updateJobListing.data[singleJob].isApplied === 1 ? 0 : 1;
        dispatch({
          type: JobsListActionTypes.JOBUPDATE,
          jobsListing: updateJobListing,
        });
      }
      toast(data.message);
    });
  };

  const saveJobHandles = async (e, listing) => {
    e.stopPropagation();

    try {
      const response = await axios.post(
        `https://api.hireboss.ca/api/job/save/${listing.id}`, // Assuming job_id is needed,
      );

      if (response.status == true) {
        setSavedJobs((prev) => ({ ...prev, [listing.id]: true })); // Mark job as saved
      }
    } catch (error) {
      console.error("Error saving job:", error.response?.data || error.message);
    }
  };

  const applyJobshandle = (e, jobApply) => {
    // /job/apply/{job_id}
    e.preventDefault();
    const JobId = jobApply.id
    PostApiCall(APIConstants.APPLYJOB_URL + JobId).then((data) => {
      if(data.status){
        toast(data.message);
      }
     if (jobApply?.url_source) {
      window.location.href = jobApply.url_source; // Redirect to the URL
    }
    })
  };

  const SavedJobs = (jobId) => {
    PostApiCall(APIConstants.SAVEJOB_URL + jobId).then((data) => {
      if (data.status) {
        let updateJobListing = jobsListState.jobsListing;
        const singleJob = updateJobListing.data.findIndex(
          (joblistingId) => joblistingId.id === jobId
        );
        if (singleJob !== -1) {
          updateJobListing.data[singleJob].isSaved =
            updateJobListing.data[singleJob].isSaved === 1 ? 0 : 1;
          dispatch({
            type: JobsListActionTypes.JOBUPDATE,
            jobsListing: updateJobListing,
          });
        }
        toast(data.message);
      } else {
        toast("You have already saved this job");
      }
    });
  };
  const savedJobshandle = (e, job) => {
    e.preventDefault();
    SavedJobs(job.id);
  };

  const handleChange = (e) => {
    const jobParams = {
      ...JobSearchParams,
      [e.target.name]: e.target.value,
    };
    setJobSearchParams(jobParams);
  };

  const nextHandler = (e) => {
    e.preventDefault();
    if (JobSearchParams !== null) {
      if (
        JobSearchParams["job_title"] !== undefined &&
        JobSearchParams["location"] !== undefined
      ) {
        searchParams.delete("job_title");
        searchParams.delete("location");
        if (JobSearchParams.title !== "") {
          searchParams.append("job_title", JobSearchParams.job_title);
        }
        if (JobSearchParams.location !== "") {
          searchParams.append("location", JobSearchParams.location);
        }
        setSearchParams(searchParams);
      } else if (JobSearchParams["location"] !== undefined) {
        if (searchParams.has("location")) {
          searchParams.delete("location");
        }
        if (JobSearchParams.location !== "") {
          searchParams.append("location", JobSearchParams.location);
        }
        setSearchParams(searchParams);
      } else if (JobSearchParams["job_title"] !== undefined) {
        if (searchParams.has("job_title")) {
          searchParams.delete("job_title");
        }
        searchParams.append("job_title", JobSearchParams.job_title);
        setSearchParams(searchParams);
      }
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
  
    const day = date.getDate();
    const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(date);
    const year = date.getFullYear();
  
    // Function to get ordinal suffix
    const getOrdinalSuffix = (day) => {
      if (day > 3 && day < 21) return "th"; // Covers 4th-20th
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };
  
    return `${month} ${day}${getOrdinalSuffix(day)}, ${year}`;
  };

  const handleSelectChange = (event, paramName) => {
    const newValue = event.target.value;
    const params = new URLSearchParams(searchParam);
  
    if (newValue) {
      params.set(paramName, newValue);
    } else {
      params.delete(paramName);
    }
  
    navigate(`?${params.toString().replace(/\+/g, "%20")}`, { replace: true });
  };
  
  

  const clearSelection = (paramName) => {
    const params = new URLSearchParams(searchParam);
    params.delete(paramName);
    navigate(`?${params.toString()}`, { replace: true });
  };

  return (
    <>
      {jobsListState?.loading && <Loader loading={jobsListState?.loading} />}
      <section class="container form-search-area">
        <div class="row">
          <div class="col-12">
            <div class="form-area">
              <form>
                <div class="form-group">
                  <div class="input-area">
                    <input
                      class="form-control"
                      type="text"
                      name="job_title"
                      onChange={handleChange}
                      placeholder="Job Title, Keywords or Employer"
                    />
                  </div>
                  <div class="input-area">
                    <input
                      class="form-control"
                      placeholder='City, Province/Territory or "remote" '
                      type="text"
                      name="location"
                      onChange={handleChange}
                    />
                  </div>
                  <button class="btn btn-apply" onClick={(e) => nextHandler(e)}>
                    Find Jobs
                  </button>
                </div>
              </form>
            </div>
            <div class="filter-buttons">
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <select className="form-select" onChange={(e) => handleSelectChange(e, "location_type")} value={selectedJobType}>
          <option value="">Select Job Type</option>
          <option value="fully remote">Fully Remote</option>
          <option value="hybrid">Hybrid</option>
          <option value="in-person">In-person</option>
        </select>
        {selectedJobType && (
          <button
            onClick={() => clearSelection("location_type")}
            style={{ background: "none", border: "none", cursor: "pointer", fontSize: "16px", color: "red" }}
          >
            ❌
          </button>
        )}
      </div>
              <select class="form-select">
                <option value="Sort-recent" selected>
                  Sort Recent to Oldest
                </option>
                <option value="Sort-oldest">Sort Oldest to Recent</option>
                <option value="">Custom Date</option>
              </select>
              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <select className="form-select" onChange={(e) => handleSelectChange(e, "location")} value={selectedLocation}>
          <option value="">Select Location</option>
          <option value="Alberta">Alberta</option>
          <option value="British Columbia">British Columbia</option>
          <option value="Manitoba">Manitoba</option>
          <option value="New Brunswick">New Brunswick</option>
          <option value="Newfoundland and Labrador">Newfoundland and Labrador</option>
          <option value="Nova Scotia">Nova Scotia</option>
          <option value="Northwest Territories">Northwest Territories</option>
          <option value="Nunavut">Nunavut</option>
          <option value="Ontario">Ontario</option>
          <option value="Prince Edward Island">Prince Edward Island</option>
          <option value="Quebec">Quebec</option>
          <option value="Saskatchewan">Saskatchewan</option>
          <option value="Yukon">Yukon</option>
        </select>
        {selectedLocation && (
          <button
            onClick={() => clearSelection("location")}
            style={{ background: "none", border: "none", cursor: "pointer", fontSize: "16px", color: "red" }}
          >
            ❌
          </button>
        )}
      </div>

 {/* Job Category Dropdown */}
      <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <select className="form-select" onChange={(e) => handleSelectChange(e, "category")} value={selectedCategory}>
          <option value="">Job Category</option>
          <option value="Full Time">Full time</option>
          <option value="Part Time">Part time</option>
          <option value="Contract">Contract</option>
          <option value="Permanent">Permanent</option>
        </select>
        {selectedCategory && (
          <button
            onClick={() => clearSelection("category")}
            style={{ background: "none", border: "none", cursor: "pointer", fontSize: "16px", color: "red" }}
          >
            ❌
          </button>
        )}
      </div>
              <div class="more-filter-button">
                <img src={FilterIcon} alt="more filter" />
                <select class="form-select">
                  <option value="" selected disabled>
                    More Filters
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="container-fluid job-listing-area">
        {jobsListState?.jobsListing?.data?.length === 0 && (
          <NoResult title={"No Jobs Available"} subtitle={""} />
        )}
        {jobsListState?.jobsListing?.data?.length > 0 &&
          jobsListState?.jobsListing?.data?.map((listing, index) => {
            return (
              <div
                class="container mb-3"
                key={index}
                onClick={(e) => applyHandler(e, listing)}
              >
                <div class="row g-4">
                  <div class="col-lg-12 col-md-12">
                    <div class="row g-0 cards-pointer">
                      <div class="col-12 job-listing">
                        <div class="card active">
                          <div class="card-body">
                            {/* <div class="logo" style={{ width: "8%" }}>
                              <span>{listing?.title?.[0]}</span>
                            </div> */}
                            <div class="content">
                              <div className="d-flex justify-content-between">
                                <div class="job-header">
                                  <h5>{listing?.title}</h5>
                                  <p>
                                    <span class="company-name mb-2">
                                      {listing?.company}
                                    </span>
                                    {/* <span class="devider">-</span> */}
                                    {/* <span class="job-count">
                                      {listing?.job_id}
                                    </span> */}
                                  </p>
                                  {listing.salary !== "" ? (
                                    <p>
                                    <span class="job-count">
                                       Salary - ${listing?.min_salary} - ${listing?.max_salary}
                                    </span>
                                  </p>
                                  ) : null}
                                  <p class="area">{listing?.job_location}</p>
                                  <ul class="keywords">
                                    <li>
                                      {listing?.job_type
                                        ? listing?.job_type
                                        : listing?.job_type == ""
                                        ? "Full Time"
                                        : listing?.job_type}
                                    </li>
                                  </ul>
                                  {/* <p class="job-type">
                                    <i
                                      class="fa fa-briefcase"
                                      aria-hidden="true"
                                    ></i>
                                    <span>flexible work</span>
                                  </p> */}
                                  <p class="job-posted">
                                    Posted :{" "}
                                    {formatDate(listing?.created_at)}
                                  </p>
                                </div>
                                <div>
                                <button
                                  className="btn btn-apply"
                                  style={{
                                    color: "black",
                                    fontFamily: "Lato",
                                  }}
                                  onClick={(e) => saveJobHandles(e, listing)}
                                >
                                  {savedJobs[listing.id] ? "Saved" : "Save"}
                                </button>{" "}
                                  {" "}
                                  <button
                                    class="btn btn-apply"
                                    style={{
                                      color: "black",
                                      fontFamily: "Lato",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation(); // Prevents card click event
                                      applyJobshandle(e, listing);
                                    }}
                                  >
                                   {listing.is_applied > 0 ? "Applied" : "Apply"}
                                  </button>{" "}
                                </div>
                              </div>
                              <div class="job-desc">
                                <ul className="truncate-list">
                                  <li
                                    dangerouslySetInnerHTML={{
                                      __html: listing?.description,
                                    }}
                                  />
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        {/* <div class="container">
          <div class="row g-4">
            <div class="col-lg-12 col-md-12">
              <div class="row g-0">
                <div class="col-12 job-listing">
                  <div class="card active">
                    <div class="card-body">
                      <div class="logo">
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/9/91/Bell_logo.svg"
                          alt="bell"
                        />
                      </div>
                      <div class="content">
                        <div class="job-header">
                          <h5>
                            Retail sales associate, halifax shopping centre
                          </h5>
                          <p>
                            <span class="company-name">Bell canada</span>
                            <span class="devider">-</span>
                            <span class="job-count">14 Jobs</span>
                          </p>
                          <p class="area">Halifax, NS</p>
                          <ul class="keywords">
                            <li>Full-time</li>
                            <li>Experienced</li>
                          </ul>
                          <p class="job-type">
                            <i class="fa fa-briefcase" aria-hidden="true"></i>
                            <span>flexible work</span>
                          </p>
                          <p class="job-posted">Today</p>
                        </div>
                        <div class="job-desc">
                          <ul>
                            <li>
                              Assist customers in finding the products to
                              improve their lives
                            </li>
                            <li>
                              Explain new technology to customers of all
                              expertise levels
                            </li>
                            <li>
                              Thrive in a fast-paced enviroment with a flexible
                              schedule
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-body">
                      <div class="logo">
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/e/e2/Wells_Fargo_Logo_%282020%29.svg"
                          alt="bell"
                        />
                      </div>
                      <div class="content">
                        <div class="job-header">
                          <h5>
                            Retail sales associate, halifax shopping centre
                          </h5>
                          <p class="company-name">
                            <span>Bell canada</span>
                            <span>-</span>
                            <span class="job-count">14 Jobs</span>
                          </p>
                          <p class="area">Halifax, NS</p>
                          <ul class="keywords">
                            <li>Full-time</li>
                            <li>Experienced</li>
                          </ul>
                          <p class="job-type">
                            <i class="fa fa-briefcase" aria-hidden="true"></i>
                            <span>flexible work</span>
                          </p>
                          <p class="job-posted">Today</p>
                        </div>
                        <div class="job-desc">
                          <ul>
                            <li>
                              Assist customers in finding the products to
                              improve their lives
                            </li>
                            <li>
                              Explain new technology to customers of all
                              expertise levels
                            </li>
                            <li>
                              Thrive in a fast-paced enviroment with a flexible
                              schedule
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </section>
      {/* <div className="container-cstm">
                    <div className="job-outer mt-0 pt-5">
                        <FilterMenu/>
                        <div className="content-inner">
                            <div className="cntnt-left">
                                <form>
                                    <div className="frm-lft">
                                        <div className="txt-otr">
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                            <input
                                                type="text"
                                                name="job_title"
                                                onChange={handleChange}
                                                placeholder="Job title, Company,…"
                                            />
                                        </div>
                                    </div>
                                    <div className="frm-rgt">
                                        <div className="txt-otr">
                                            <i className="fa fa-map-marker" aria-hidden="true"></i>
                                            <input
                                                type="text"
                                                name="location"
                                                onChange={handleChange}
                                                placeholder="City, State,…"
                                            />
                                        </div>
                                    </div>
                                    <input
                                        type="submit"
                                        className="btn fa-input"
                                        value="Search   &#xf061; "
                                        onClick={(e) => nextHandler(e)}
                                    />
                                </form>
                            </div>
                            {jobsListState.jobsListing.data?.length === 0 && (
                                <NoResult title={"No Jobs Available"} subtitle={""}/>
                            )}
                            {jobsListState.jobsListing.data?.length > 0 &&
                                jobsListState.jobsListing.data.map((listing, index) => {
                                    return (
                                        <>
                                            <div className="cmp-desg-apply-section" key={index}>
                                                <div
                                                    className="cmp-desg"
                                                    onClick={(e) => applyHandler(e, listing)}
                                                >
                                                    <img src={listing.logo} alt={listing.company_name}/>
                                                    <div className="desg-sec">
                                                        <p>{listing.title}</p>
                                                        <p>
                                                            <span>
                                {listing.company_name}.{listing.city} , {listing.state},{" "}
                                                                {listing.country} .{" "}
                                                                {listing.job_type}
                              </span>
                                                        </p>
                                                        <p>
                                                            {moment(listing.created_at).format("MM-DD-YYYY")}
                                                        </p>
                                                        {listing.remote === "Yes" && <p>Remote</p>}
                                                    </div>
                                                </div>
                                                {userIdContext !== null &&
                                                    roleTypeContext !== "Employer" && (
                                                        <div className="apply-section">
                                                            <p
                                                                className="bookmark"
                                                                onClick={(e) => savedJobshandle(e, listing)}
                                                            >
                                                                <a
                                                                    href="#"
                                                                    className={`${
                                                                        listing.isSaved === 1 && "active"
                                                                    }`}
                                                                >
                                                                    <i
                                                                        class="fa fa-bookmark-o"
                                                                        aria-hidden="true"
                                                                    ></i>
                                                                    <i
                                                                        class="fa fa-bookmark"
                                                                        aria-hidden="true"
                                                                    ></i>
                                                                </a>
                                                            </p>
                                                            {listing.isApplied === 0 && (
                                                                <p
                                                                    className="apply-btn"
                                                                    onClick={(e) => applyJobshandle(e, listing)}
                                                                >
                                                                    <a href="">
                                                                        {" "}
                                                                        Apply Now
                                                                        <i
                                                                            className="fa fa-arrow-right"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                    </a>
                                                                </p>
                                                            )}
                                                            {listing.isApplied === 1 && (
                                                                <p className="apply-btn applied">
                                                                    <a
                                                                        href=""
                                                                        onClick={(e) => e.preventDefault()}
                                                                    >
                                                                        {" "}
                                                                        Applied
                                                                    </a>
                                                                </p>
                                                            )}
                                                        </div>
                                                    )}
                                            </div>
                                        </>
                                    );
                                })}
                            {jobsListState.jobsListing.data?.length > 0 &&
                                jobsListState.jobsListing.total > 10 && (
                                    <Pagination
                                        perPage={jobsListState.jobsListing.per_page}
                                        total={jobsListState.jobsListing.total}
                                    />
                                )}
                        </div>
                    </div>
                </div> */}
      <Footer />
    </>
  );
};
export default JobPage;
