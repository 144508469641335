// export const baseUrl = "http://dev81.developer24x7.com/cnp1677-api/public/api/";
export const baseUrl = "https://api.hireboss.ca/api/";

export const APIConstants = {
  LOGIN_URL: baseUrl + "login",
  REG_URL: baseUrl + "register",
  VERIFYEMAIL_URL: baseUrl + "verify-email",
  FORGOTPASSWORD_URL: baseUrl + "forgot-password",
  RESETPASSWORD_URL: baseUrl + "reset-password",
  CHANGEPASSWORD_URL: baseUrl + "profile/change-password",
  CMS_ABOUT_URL: baseUrl + "cms/about-us",
  CMS_STAFFING_URL: baseUrl + "cms/staffing",
  CMS_PRICING_URL: baseUrl + "membership-plans",
  SAVED_JOBS_URL: baseUrl + "candidate/job/saved-list",
  APPLIED_JOBS: baseUrl + "candidate/job/applied-list",
  CAREER_ALERT_SHOW: baseUrl + "career-alert/show",
  CMS_TERMS_URL: baseUrl + "cms/terms",
  CMS_PRIVACY_POLICY_URL: baseUrl + "cms/privacy-policy",
  CMS_SUPPORT_URL: baseUrl + "cms/support",
  NEWLETTER_EMAIL: baseUrl + "addnewsletter",
  DELETE_SAVED_JOB: baseUrl + "career-alert/delete",
  DEACTIVATE_JOB: baseUrl + "career-alert/update-status",
  CAREER_ALEART: baseUrl + "career-alert",
  SUPPORT_FORM: baseUrl + "contact",
  STAFFING: baseUrl + "staffing",
  MEMBERSHIP_PLANID: baseUrl + "call-payment-intent",
  RESEND_OTP: baseUrl + "resend-otp",
  POST_JOB: baseUrl + "job/create",

  // Employer Apis
  EMPLOYERUPDATE_URL: baseUrl + "company/update/",
  INDUSTRIES_URL: baseUrl + "industries",
  COMPANYSIZES_URL: baseUrl + "company-sizes",
  MEMBERSHIPPLANS_URL: baseUrl + "membership-plans",
  COMPANYCREATION_URL: baseUrl + "company/create/",
  JOBROLES_URL: baseUrl + "job/types",
  JOBCREATE_URL: baseUrl + "job/create/info",
  UPDATEJOB_URL: baseUrl + "job/update/info/",
  JOBCREATEDESCRIPTION_URL: baseUrl + "job/description/",
  JOBQUESTIONARIES_URL: baseUrl + "job/questionaries/",
  JOBPREVIEW_URL: baseUrl + "job/preview/",
  EMPLOYER_MY_JOBS_URL: baseUrl + "employer-my-jobs",
  VIEWPROFILE_URL: baseUrl + "profile",
  MYDASHBOARD_URL: baseUrl + "employer-my-dashboard",
  EMPLOYER_MY_JOBS_APPLICANTS_URL:
    baseUrl + "employer-my-jobs-applicants?job_id=",
  EMPLOYER_MY_JOBS_APPLICANTS_DETAILS_URL:
    baseUrl + "employer-my-jobs-applicants-details?job_candidate_id=",
  EMPLOYER_MY_JOBS_APPLICANTS_STATUS_URL:
    baseUrl + "employer-my-jobs-applicant-status-update",
  JOBEXPERIENCE_URL: baseUrl + "experiences",
  TRANSACTION_HISTORY_URL: baseUrl + "membership-plans-history",
  GET_WHOLE_JOB : 'employer-my-jobs  ',

  // Candidates Apis
  ACCOUNTPROFILEUPDATE_URL: baseUrl + "profile/update",
  ACCOUNTPROFILEUPLOADIMAGE_URL: baseUrl + "profile/upload_image",
  JOBLIST_URL: baseUrl + "jobs-search",
  FILTERSIDEMENU_URL: baseUrl + "job/side-menu",
  SKILLS_URL: baseUrl + "skills",
  CANDIDATERESUME_URL: baseUrl + "candidate-resume",
  CANDIDATEWORKHISTORY_URL: baseUrl + "candidate/work-history",
  CANDIDATEUPDATEWORKHISTORY_URL: baseUrl + "candidate/update-work-history/",
  CANDIDATEGETRESUMESKILLHISTORY_URL:
    baseUrl + "candidate/get-resume-skill-workhistory",
  APPLYJOB_URL: baseUrl + "job/apply/",
  SAVEJOB_URL: baseUrl + "job/save/",
  JOBSAVEDLIST_URL: baseUrl + "candidate/job/saved-list",
  JOBAPPLIEDLIST_URL: baseUrl + "candidate/job/applied-list",
  JOBAPPLIEDSIDEMENU_URL: baseUrl + "candidate/job/applied-list-sidemenu",
  JOBSAVEDSIDEMENU_URL: baseUrl + "candidate/job/saved-list-sidemenu",
  CANDIDATEWORKHISTORYDELETE_URL: baseUrl + "candidate/delete-work-history/",
  ONLINESTATUS_URL: baseUrl + "candidate/change-online-status/",
  PAYMENT_INTENT: baseUrl + "call-payment-intent",
  PAYSUBSCRIBEMEMBERSHIP: baseUrl + "pay-subscribe-membership",
  RESETPASSWORDTOKEN: baseUrl + "reset-password-token",
  PAYMENTSTATUS: baseUrl + "employer-payment-status",
};

export const STRIPE_KEY =
  "pk_test_51NWJrsEN5CVwck5Dm55yAcq71sBesQlXb7MD3eDWDXwJJdBU5QMgwrAaRFvYar49r4cMYlFMm3ZGVGDtVmXFhnx600EfCBWz1a";

export const STRIPE_ACCOUNTID = "acct_1NWJrsEN5CVwck5D";

export function isValidEmail(email) {
  return /\S+@\S+\.\S+/.test(email);
}

export function debounce(fn, delay) {
  let timer;
  return function () {
    let context = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
}
