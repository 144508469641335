// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-container {
    padding: 20px;
}
.nav-tabs .nav-link {
    color: black;
    font-weight: bold;
}
.nav-tabs .nav-link.active {
    color: #ffd42f;
}
.cardss {
    text-align: center;
    padding: 20px;
    transition: 0.3s;
    cursor: pointer;
    height: 200px;  /* Ensuring same height for all cards */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #F9F9FB;
}
.card:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.icon-img {
    width: 50px;  /* Adjust size as needed */
    height: 50px;
    margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,YAAY;IACZ,iBAAiB;AACrB;AACA;IACI,cAAc;AAClB;AACA;IACI,kBAAkB;IAClB,aAAa;IACb,gBAAgB;IAChB,eAAe;IACf,aAAa,GAAG,uCAAuC;IACvD,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,yBAAyB;AAC7B;AACA;IACI,2CAA2C;AAC/C;AACA;IACI,WAAW,GAAG,0BAA0B;IACxC,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":[".dashboard-container {\n    padding: 20px;\n}\n.nav-tabs .nav-link {\n    color: black;\n    font-weight: bold;\n}\n.nav-tabs .nav-link.active {\n    color: #ffd42f;\n}\n.cardss {\n    text-align: center;\n    padding: 20px;\n    transition: 0.3s;\n    cursor: pointer;\n    height: 200px;  /* Ensuring same height for all cards */\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    background-color: #F9F9FB;\n}\n.card:hover {\n    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);\n}\n.icon-img {\n    width: 50px;  /* Adjust size as needed */\n    height: 50px;\n    margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
