import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import BackArraw from "../../Dashboard/image/arrow.png";
import { GetApiCall, PostApiCall } from "../../../utils/axios";
import { APIConstants } from "../../../utils/config";
import { FaPlay, FaPause, FaTimes, FaEye } from "react-icons/fa"; // Import icons
import axios from "axios";

const JobManager = ({ setShowjobManager }) => {
  const [datas, setData] = useState([]);

  useEffect(() => {
    GetApiCall(APIConstants.GET_WHOLE_JOB).then((data) => {
      setData(data.data);
      // setStaffingPage(data)
    });
  }, []);

  const handleActionClick = async (action, jobId) => {
    console.log(`Performing ${action} action on job ID: ${jobId}`);
    try {
      const response = await axios.post(
        `https://api.hireboss.ca/api/job/update/${jobId}`, {
          job_status : action
        } // Assuming job_id is needed,
      );

      if (response.status === 200) {
        GetApiCall(APIConstants.GET_WHOLE_JOB).then((data) => {
          setData(data.data);
          // setStaffingPage(data)
        });
      }
    } catch (error) {
      console.error("Error saving job:", error.response?.data || error.message);
    }
    // Call your API here based on the action
  };

  const columns = [
    {
      name: "Job Title",
      selector: (row) => row.title,
      sortable: true,
      style: { fontFamily: "Lato", fontSize: "18px" },
    },
    {
      name: "Job Status",
      selector: (row) => row.job_status,
      sortable: true,
      style: { fontFamily: "Lato", fontSize: "18px" },
    },
    {
      name: "Post Date",
      selector: (row) =>
        new Date(row.created_at).toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }),
      sortable: true,
      style: { fontFamily: "Lato", fontSize: "18px" },
    },
    {
      name: "Expiration Date",
      selector: (row) =>
        new Date(row.expiration_date).toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }),
      sortable: true,
      style: { fontFamily: "Lato", fontSize: "18px" },
    },
    {
      name: "Applies",
      selector: (row) => row.applies,
      sortable: true,
      style: { fontFamily: "Lato", fontSize: "18px" },
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex gap-2">
          <button
            className="btn btn-success btn-sm"
            onClick={() => handleActionClick("Open", row.id)}
          >
            <FaPlay /> {/* Open */}
          </button>
          <button
            className="btn btn-warning btn-sm"
            onClick={() => handleActionClick("Pause", row.id)}
          >
            <FaPause /> {/* Pause */}
          </button>
          <button
            className="btn btn-info btn-sm"
            onClick={() => handleActionClick("In review", row.id)}
          >
            <FaEye /> {/* In Review */}
          </button>
          <button
            className="btn btn-danger btn-sm"
            onClick={() => handleActionClick("Close", row.id)}
          >
            <FaTimes /> {/* Close */}
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <div className="container mt-4">
      <img
        src={BackArraw}
        style={{
          height: "30px",
          width: "30px",
          marginBottom: "10px",
          cursor: "pointer",
        }}
        onClick={() => {
          setShowjobManager(false);
        }}
      />
      <div className="card shadow-lg">
        <div
          className="card-header text-white"
          style={{ backgroundColor: "#ffd42f" }}
        >
          <h4 className="mb-0">Job Management</h4>
        </div>
        <div className="card-body">
          <DataTable
            columns={columns}
            data={datas}
            pagination
            responsive
            className="table-striped table-hover"
          />
        </div>
      </div>
    </div>
  );
};

export default JobManager;
