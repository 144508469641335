/* eslint-disable jsx-a11y/anchor-is-valid */

import { useContext, useEffect, useState } from "react";
import {
  GetApiCall,
  PostApiCall,
  UploadFormApiCall,
} from "../../../utils/axios";
import { APIConstants } from "../../../utils/config";
import Loader from "../../../components/loader";
import ChangePasswordPage from "../../Authentications/Candidates/changepassword";
import { toast } from "react-toastify";
import React from "react";
import { AuthContext, UserIdContext } from "../../../contexts/auth-context";
import ProfileImage from "../../../assets/images/istockphoto-1495088043-612x612.jpg"

const AccountSettingsPage = () => {
  const [createAccount, setCreateAccount] = useState({});
  const [uploadImage, setUploadImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isChangePasswordVisible, setIsChangePasswordVisible] = useState(false);
  const [onlineStatus, setOnlineStatus] = useState("0");
  const auth = useContext(AuthContext);
  const userIdContext = useContext(UserIdContext);

  const changePasswordClick = () => {
    setIsChangePasswordVisible(true);
  };
  const closeAuthPopup = (event) => {
    setIsChangePasswordVisible(event);
  };

  const onlineStatusClick = (status) => {
    onlineStatusApi(status);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const accountCreationBody = {
      ...createAccount,
      [e.target.name]: e.target.value,
    };
    setCreateAccount(accountCreationBody);
  };
  const Createaccount = () => {
    setLoading(true);
    const accountProfileBody = {
      ...createAccount,
      city: "hyderbad",
      state: "telangana",
      google_location: "hyderbad",
      latitude: "17.3850",
      longitude: "17.3850",
      zip: "500019",
    };
    PostApiCall(APIConstants.ACCOUNTPROFILEUPDATE_URL, accountProfileBody).then(
      (data) => {
        console.log(data, "data")
        setLoading(false);
        toast(data.message);
      }
    );
  };

  useEffect(() => {
    viewProfileAPI();
  }, []);

  const viewProfileAPI = () => {
    setLoading(true);
    GetApiCall(APIConstants.VIEWPROFILE_URL).then((data) => {
      const accountProfileBody = {
        ...data,
        address: data.candidate_profile?.address,
      };
      setCreateAccount(accountProfileBody);
      setLoading(false);
      setOnlineStatus(data.is_online);
    });
  };

  const onlineStatusApi = (status) => {
    GetApiCall(APIConstants.ONLINESTATUS_URL + status).then((data) => {
      setOnlineStatus((prevState) => data.data.is_online);
      toast(data.message);
    });
  };

  const createAccounthandle = (e) => {
    e.preventDefault();
    Createaccount();
    if (uploadImage !== null) {
      uploadImageAccount();
    }
  };

  const handleUploadImage = (e) => {
    e.preventDefault();
    setUploadImage((prev) => e.target.files[0]);
  };

  const uploadImageAccount = () => {
    setLoading(true);
    const imageUploadBody = {
      image: uploadImage,
    };
    UploadFormApiCall(
      APIConstants.ACCOUNTPROFILEUPLOADIMAGE_URL,
      imageUploadBody
    ).then((data) => {
      const updateProfile = userIdContext;
      if (updateProfile !== null) {
        updateProfile.profile_image = data.data?.profile_image;
        auth.profileUpdate(updateProfile);
      }
      setLoading(false);
      toast("Profile Image Updated Successfully");
    });
  };

  return (
    <>
      {loading && <Loader loading={loading} />}
      <div id="acc-sts" className="tabcontent">
        {isChangePasswordVisible && (
          <ChangePasswordPage
            isVisible={isChangePasswordVisible}
            closeAuthPopup={closeAuthPopup}
          />
        )}
        <form>
          <div className="tabcontent-inner" style={{width: 'auto', paddingTop: '20px', marginTop: '-45px', marginBottom: '10px'}}>
            <div className="acc-sts-left">
              <input
                type="text"
                placeholder="E-mail"
                onChange={handleChange}
                name="designation"
                value={createAccount?.email}
                disabled
              />
              <input
                type="text"
                placeholder="First Name"
                onChange={handleChange}
                name="name"
                value={createAccount?.name}
              />
              <input
                type="text"
                placeholder="Last name"
                onChange={handleChange}
                name="last_name"
                value={createAccount?.last_name}
              />
              <input
                type="text"
                placeholder="Phone Number"
                onChange={handleChange}
                name="phone_no"
                value={createAccount?.phone_no}
              />
              <input
                type="text"
                placeholder="Location"
                onChange={handleChange}
                name="address"
                value={createAccount?.address}
              />
               <div
                className="file-upload-wrapper"
                style={{margin: '0px'}}
                data-text={"Upload profile Picture"}
              >
                <input
                  accept="image/*"
                  name="file-upload-field"
                  type="file"
                  className="file-upload-field"
                  onChange={handleUploadImage}
                />
              </div>
              <p className="mt-3">
            <a href="#" onClick={() => changePasswordClick()}>
              Change Password
            </a>{" "}
          </p>
          <input
            type="submit"
            style={{marginTop: '10px', marginBottom: '10px'}}
            value="Save"
            onClick={(e) => createAccounthandle(e)}
          />
            </div>

            <div className="acc-sts-right">
              <div className="img-wrap">
                {uploadImage !== null ? (
                  <img src={URL.createObjectURL(uploadImage)} alt="" />
                ) : uploadImage == null ? (
                  <img src={ProfileImage} />
                ) : (
                  <img
                    src={
                      createAccount.profile_image !== undefined &&
                      createAccount.profile_image
                    }
                    alt=""
                  />
                )}
              </div>

              {/* <p className="account-status">
                <span
                  className={onlineStatus === "1" && `active`}
                  onClick={() => onlineStatusClick(1)}
                >
                  Online
                </span>
                <span
                  className={onlineStatus === "0" && `active`}
                  onClick={() => onlineStatusClick(0)}
                >
                  Invisible
                </span>
              </p> */}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default AccountSettingsPage;
