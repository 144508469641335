import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { GetApiCall, PostApiCall } from "../../../utils/axios"; 
import { APIConstants } from "../../../utils/config";
import "bootstrap/dist/css/bootstrap.min.css";

const MyJobAlertPage = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    GetApiCall(APIConstants.CAREER_ALERT_SHOW).then((response) => {
      if (response?.data) {
        setData(response.data);
      }
    });
  }, []);

  const handleDelete = (PostId) => {
    PostApiCall(APIConstants.DELETE_SAVED_JOB, { id: PostId }).then(() => {
      setData((prevData) => prevData.filter((job) => job.id !== PostId));
    });
  };

  const handleDeactivate = (PostId) => {
    PostApiCall(APIConstants.DEACTIVATE_JOB, { id: PostId }).then(() => {
      setData((prevData) =>
        prevData.map((job) =>
          job.id === PostId ? { ...job, status: 2 } : job
        )
      );
    });
  };

  const columns = [
    {
      name: "Job Title",
      selector: (row) => row.job_title,
      sortable: true,
      style: { fontFamily: "Lato", fontSize: "18px" },
    },
    {
      name: "Status",
      selector: (row) => (row.status === 1 ? "Active" : "Deactive"),
      sortable: true,
      style: { fontFamily: "Lato", fontSize: "18px" },
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <button className="btn btn-danger btn-sm" onClick={() => handleDelete(row.id)}>
            Delete
          </button>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
        name: "Status",
        cell: (row) => (
            <button className="btn btn-warning btn-sm me-2" onClick={() => handleDeactivate(row.id)}>
            {row.status === 1 ? "Deactivate" : "Deactivated"}
          </button>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
  ];

  return (
    <div className="container mt-4">
      <div className="card shadow-lg">
        <div className="card-header text-white" style={{ backgroundColor: "#ffd42f" }}>
          <h4 className="mb-0">My Job Alerts</h4>
        </div>
        <div className="card-body">
          <DataTable columns={columns} data={data} pagination responsive className="table-striped table-hover" />
        </div>
      </div>
    </div>
  );
};

export default MyJobAlertPage;
