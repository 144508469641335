// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState, useContext } from "react";
import { GetApiCall } from "../../../utils/axios";
import { APIConstants } from "../../../utils/config";
import Loader from "../../../components/loader";
import NoResult from "../../../components/no-result";
import Chart from "react-google-charts";
import { useNavigate } from "react-router-dom";
import Footer from "../../../components/footer";
import Correct from "../../Dashboard/image/correct.png"
import Setting from "../../Dashboard/image/setting.png"
import Basket from "../../Dashboard/image/basket.png"
import History from "../../Dashboard/image/history.png"
import Profile from "../../Dashboard/image/profile.png"
import LogoutImage from "../../Dashboard/image/logout.png"
// import { AuthContext } from "../../contexts/auth-context";
import { AuthContext } from "../../../contexts/auth-context";
import JobForm from "./job-frm";
import JobManager from "./job-manager";

const DashBoardPage = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [chartDashBoardList, setchartDashBoardList] = useState([]);
  const [showJobForm, setShowjobForm] = useState(false);
  const [showJobManager, setShowjobManager] = useState(false);

  const myDashBoard = () => {
    setLoading(true);
    GetApiCall(APIConstants.MYDASHBOARD_URL).then((data) => {
      if (data?.data) {
        const chartDashboard = [...chartDashBoardList];
        chartDashboard.push([
          "Job Title",
          "New",
          "Under Review",
          "Rejected",
          "Accepted",
        ]);
        for (const element of data.data) {
          delete element["total_jobs"];
          const v = Object.values(element);
          chartDashboard.push(v);
          setchartDashBoardList(chartDashboard);
        }
      }
      setLoading(false);
    });
  };
  const checkPaymentStatus = () => {
    GetApiCall(APIConstants.PAYMENTSTATUS).then((response) => {
      setPaymentStatus(response?.data?.subscription_plan === null);
    });
  };

  useEffect(() => {
    myDashBoard();
    checkPaymentStatus();
  }, []);

  const paymentNavigation = () => {
    navigate("/payment-selection");
  };

  const handleProductClick = () => {
    navigate("/dashboard/pricing-page");
  }

  const handleBillingClick = () => {
    navigate("/dashboard/billing-page");
  }

  const options = {
    title: "Jobs Dashboard",
    chartArea: { width: "50%" },
    isStacked: true,
    colors: ["#FFD42F", "#8474A1", "#FE5E5E", "#089896"],
    legend: "none",
    hAxis: {
      title: "Status of jobs",
      minValue: 0,
    },
    vAxis: {
      title: "Jobs",
    },
  };
  const handleLogout = async () => {
    try {
      await auth.signOut();
      localStorage.setItem("firstVisit", "true");
      navigate("/");
      window.location.reload(); // Optional: Reload to reflect logout state
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const handleJobForm = () => {
    setShowjobForm(true)
  }
  const handleJobManager = () => {
    setShowjobManager(true);
  }


  return (
    <>

    {showJobForm == true ? (
      <JobForm setShowjobForm={setShowjobForm} />
    ) : showJobManager == true ? (
      <JobManager setShowjobManager={setShowjobManager} />
    ) : (
      <div class="container dashboard-container">
          <ul class="nav nav-tabs mt-5">
        <li class="nav-item">
          <a class="nav-link active p-3" data-bs-toggle="tab" href="#jobs">
            JOBS MANAGER
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link p-3" data-bs-toggle="tab" href="#resumes">
            MANAGE ACCOUNT
          </a>
        </li>
      </ul>
      <div class="tab-content mt-4">
      <div class="tab-pane fade show active" id="jobs">
          <div class="row g-4">
            <div class="col-md-3">
              <div class="cardss" onClick={handleJobForm}>
                <img src={Correct} alt="Job Search" class="icon-img" />
                <h5 style={{ color: "#ffd42f", fontFamily: "Lato" }}>
                  Post a Job
                </h5>
                <p style={{ fontFamily: "Lato" }}>create a job posting</p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="cardss" onClick={handleJobManager}>
                <img src={Setting} alt="Jobs Applied" class="icon-img" />
                <h5 style={{ color: "#ffd42f", fontFamily: "Lato" }}>
                  Jobs Manager
                </h5>
                <p style={{ fontFamily: "Lato" }}>
                  Manage and upgrade your posted jobs
                </p>
              </div>
            </div>
            {/* <div class="col-md-3">
              <div class="cardss" onClick={handleSavedJobsPage}>
                <img src={SavedDoc} alt="Saved Jobs" class="icon-img" />
                <h5 style={{ color: "#ffd42f", fontFamily: "Lato" }}>
                  Saved Jobs
                </h5>
                <p style={{ fontFamily: "Lato" }}>
                  View and manage your saved jobs
                </p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="cardss" onClick={handlejobAlert}>
                <img src={RingingBell} alt="Job Alerts" class="icon-img" />
                <h5 style={{ color: "#ffd42f", fontFamily: "Lato" }}>
                  Job Alerts
                </h5>
                <p style={{ fontFamily: "Lato" }}>
                  Review and manage your job alerts
                </p>
              </div>
            </div> */}
          </div>
        </div>
               {/* <!-- Resumes Section --> */}
               <div class="tab-pane fade" id="resumes">
          <div class="row g-4">
            <div class="col-md-3">
              <div class="cardss" onClick={handleProductClick}>
                <img src={Basket} alt="Jobs Applied" class="icon-img" />
                <h5 style={{ color: "#ffd42f", fontFamily: "Lato" }}>
                  Purchase Products
                </h5>
                <p style={{ fontFamily: "Lato" }}>
                 Buy job posting
                </p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="cardss" onClick={handleBillingClick}>
                <img src={History} alt="Jobs Applied" class="icon-img" />
                <h5 style={{ color: "#ffd42f", fontFamily: "Lato" }}>
                 Billing history
                </h5>
                {/* <p style={{ fontFamily: "Lato" }}>
                 Buy job posting
                </p> */}
              </div>
              
            </div>
            <div class="col-md-3">
              <div class="cardss">
                <img src={Profile} alt="Jobs Applied" class="icon-img" />
                <h5 style={{ color: "#ffd42f", fontFamily: "Lato" }}>
                 Company Profile & Logo
                </h5>
                <p style={{ fontFamily: "Lato" }}>
                 manage your profile info
                </p>
              </div>
              
            </div>
            <div class="col-md-3">
              <div class="cardss" onClick={handleLogout}>
                <img src={LogoutImage} alt="Jobs Applied" class="icon-img" />
                <h5 style={{ color: "#ffd42f", fontFamily: "Lato" }}>Logout</h5>
                <p style={{ fontFamily: "Lato" }}>Logout from your account</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div className="grey-bg pt-5">
    //   {loading && <Loader loading={loading} />}
    //   {paymentStatus && (
    //     <>
    //       <div className="dashboard-head container-cstm container-cstm-mobile">
    //         <p>To post the job its mandatory to subscribe</p>
    //         <input
    //           type="submit"
    //           onClick={paymentNavigation}
    //           value="Subscribe"
    //           className="subcribe-btn"
    //         />
    //       </div>
    //     </>
    //   )}
    //   {chartDashBoardList.length === 0 && (
    //     <NoResult title={"No dashboard data"} subtitle="" />
    //   )}
    //   {chartDashBoardList.length > 0 && (
    //     <div className="candidate-outer ">
    //       <div className="container-cstm">
    //         <div className="dashboard-outer mt-0">
    //           <h3>My Active Jobs</h3>
    //           {chartDashBoardList.length > 0 && (
    //             <Chart
    //               chartType="BarChart"
    //               width="100%"
    //               height="400px"
    //               data={chartDashBoardList}
    //               options={options}
    //             />
    //           )}
    //           <div className="segment-section">
    //             <p>
    //               <i className="fa fa-square yellow" aria-hidden="true"></i>New
    //             </p>
    //             <p>
    //               <i className="fa fa-square purple" aria-hidden="true"></i>{" "}
    //               Under Review
    //             </p>
    //             <p>
    //               <i className="fa fa-square red" aria-hidden="true"></i>
    //               Rejected
    //             </p>
    //             <p>
    //               <i className="fa fa-square green" aria-hidden="true"></i>
    //               Accepted
    //             </p>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   )}
    //   <Footer />
    // </div>
    )}

    </>
  );
};
export default DashBoardPage;
