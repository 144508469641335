import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js"; // Ensure Bootstrap JS is included
import { PostApiCall } from "../../../utils/axios";
import { APIConstants } from "../../../utils/config";

const FirstVisitView = ({ setShowModal }) => {
  const [email, setEmail] = useState("");

  const handleButtonClicked = (e) => {
    e.preventDefault();
    const resetTokenBody = {
      email: email,
    };
    PostApiCall(APIConstants.NEWLETTER_EMAIL, resetTokenBody).then(
      (response) => {
        if (response.status) {
          setShowModal(false);
        }
      }
    );
  };
  return (
    <>
      {/* Bootstrap Modal */}
      <div className={`modal popup-form d-flex signup`}>
        <div className="modal-content p-2">
          <span className="close" onClick={() => setShowModal(false)}>
            &times;
          </span>
          <h2 style={{display: 'flex', justifyContent: 'center'}}>Welcome To Hireboss!</h2>
          <h4
            className="text-center pb-2"
            style={{ paddingLeft: "3.5rem", paddingRight: "3.5rem", marginBottom: '20px' }}
          >
            We're so happy you're here. Enter your email below to be part of our
            community!
          </h4>
          <form onSubmit={handleButtonClicked}>
            <input
              type="text"
              placeholder="E-mail"
              name="email"
              id="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <input type="submit" className="mb-3" value="Signup" />
          </form>
        </div>
      </div>
    </>
  );
};

export default FirstVisitView;
