// @ts-nocheck
import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { AuthenicationActionTypes } from "../../../redux/authenication/authenication-enum";
import {
  verifyEmailApi,
  loginApi,
} from "../../../redux/authenication/authenication-saga";
import Loader from "../../../components/loader";
import { AuthContext } from "../../../contexts/auth-context";
// import { PostApiCall } from "../../utils/axios";
import { PostApiCall } from "../../../utils/axios";
import { APIConstants } from "../../../utils/config";

const VerifyEmailPopUpPage = ({
  isVisible,
  closeAuthPopup,
  isVerifyEmailOtp,
  userSignUpPass,
}) => {
  const loginState = useSelector((state) => state.LoginState);
  const auth = useContext(AuthContext);
  const Passwords = localStorage.getItem("userPassword");

  useEffect(() => {
    if (isVisible) {
      var modal = document.getElementById("em-otp");
      modal.style.display = "block";
    }
  }, [isVisible]);

  const closeSignIn = () => {
    var modal = document.getElementById("em-otp");
    modal.style.display = "none";
    closeAuthPopup(false);
    dispatch({
      type: AuthenicationActionTypes.CLEARVALUES,
    });
  };

  const dispatch = useDispatch();
  const verifyEmailState = useSelector((state) => state.VerifyEmailOTPState);
  const [formData, setFormData] = useState({
    email: isVerifyEmailOtp,
    otp: "",
  });
  const [otpData, setOtpData] = useState({});
  const handleChange = (e) => {
    setOtpData({
      ...otpData,
      [e.target.name]: e.target.value,
    });
    const active = document.activeElement;
    if (active?.nextElementSibling) {
      active.nextElementSibling.focus();
    }
  };
  const onDelete = (e) => {
    // if (e.keyCode === 8) {
    //   const active = document.activeElement;
    //   if (active?.previousElementSibling) {
    //     active.previousElementSibling.focus();
    //   }
    // }
  };
  useEffect(() => {
    if (
      verifyEmailState?.isEmailVerified !== null &&
      verifyEmailState?.isEmailVerified &&
      loginState.loginStatus
    ) {
      toast("Email verification successful");
      closeAuthPopup(false);
      auth.signIn(
        JSON.stringify(loginState.loginData.data),
        loginState.loginData.role_type
      );
    } else if (
      verifyEmailState?.isEmailVerified !== null &&
      !verifyEmailState?.isEmailVerified
    ) {
      toast("Invalid verification code");
    }
  }, [verifyEmailState, loginState]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (Object.keys(otpData).length > 0) {
      let otpValue;
      for (const key in otpData) {
        if (otpValue !== undefined) {
          otpValue = otpValue + otpData[key];
        } else {
          otpValue = otpData[key];
        }
      }
      if (otpValue !== undefined && otpValue.length < 6) {
        toast("Please enter 6 digits code");
      } else {
        const verifyBody = {
          email: isVerifyEmailOtp,
          otp: otpValue,
        };
        const userData = {
          email: isVerifyEmailOtp,
          password: userSignUpPass,
        };
        const userDataWithPass = {
          email: isVerifyEmailOtp,
          password: Passwords,
        };
        dispatch({
          type: AuthenicationActionTypes.CLEARVALUES,
        });
        dispatch(verifyEmailApi(verifyBody));
        dispatch(loginApi(userDataWithPass));
      }
    } else {
      toast("Please enter 6 digits code");
    }
  };

  const handleSubmitResend = (e) => {
    e.preventDefault();
    console.log(e, "e");
    const verifyBody = {
      email: isVerifyEmailOtp,
    };
    try {
      PostApiCall(APIConstants.RESEND_OTP, verifyBody).then((response) => {
        toast("Code send successfully.");
      });
      // Reset form
    } catch (error) {
      console.error("Error submitting support request:", error);
      toast("Failed to submit request.");
    }
  };

  return (
    <div id="em-otp" class="modal popup-form forgetpass">
      {verifyEmailState.loading && (
        <Loader loading={verifyEmailState.loading} />
      )}
      <div class="modal-content" style={{ borderRadius: "20px" }}>
        <span class="close" onClick={() => closeSignIn()}>
          &times;
        </span>
        <h2>Email Verification </h2>
        <p>
          We've sent a verification code to your email. Please enter the code
          below.
          {/* A OTP (One Time Password)
                    has been sent to your Email */}
        </p>
        <form onSubmit={handleSubmit}>
          <div class="otp-field d-flex gap-2">
            <input
              type="text"
              name="otp"
              class="text-center pl-0"
              placeholder=""
              maxlength="1"
              style={{ borderRadius: "15px" }}
              onChange={handleChange}
              onKeyDown={onDelete}
            />
            <input
              type="text"
              name="otp1"
              class="text-center pl-0"
              style={{ borderRadius: "15px" }}
              placeholder=""
              maxlength="1"
              onChange={handleChange}
              onKeyDown={onDelete}
            />
            <input
              class="text-center pl-0"
              type="text"
              name="otp2"
              placeholder=""
              style={{ borderRadius: "15px" }}
              maxlength="1"
              onChange={handleChange}
              onKeyDown={onDelete}
            />
            <input
              type="text"
              name="otp3"
              class="text-center pl-0"
              placeholder=""
              maxlength="1"
              style={{ borderRadius: "15px" }}
              onChange={handleChange}
              onKeyDown={onDelete}
            />
            <input
              type="text"
              name="otp4"
              class="text-center pl-0"
              placeholder=""
              style={{ borderRadius: "15px" }}
              maxlength="1"
              onChange={handleChange}
              onKeyDown={onDelete}
            />
            <input
              type="text"
              name="otp5"
              class="text-center pl-0"
              placeholder=""
              style={{ borderRadius: "15px" }}
              maxlength="1"
              onChange={handleChange}
              onKeyDown={onDelete}
            />
          </div>
          <input
            class="otp-btn"
            style={{ backgroundColor: "#ffd42f", color: "black" }}
            type="submit"
            value="Validate Code"
          />
          <h5
            onClick={handleSubmitResend}
            style={{
              marginTop: "10px",
              cursor: "pointer",
              color: "blue",
              fontFamily: "Lato",
            }}
          >
            Resend Code
          </h5>
        </form>
      </div>
    </div>
  );
};
export default VerifyEmailPopUpPage;
