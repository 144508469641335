import React, {useState, useEffect} from "react";
import Banner from "../../Dashboard/image/banner.jpg"
// import { GetApiCall } from "../../utils/axios";
import { GetApiCall, PostApiCall } from "../../../utils/axios";
import { APIConstants } from "../../../utils/config";
import { toast } from "react-toastify";


const PriceingPage = () => {
  const [PricingPage, setPricingPage] = useState([]);

    useEffect(() => {
      GetApiCall(APIConstants.CMS_PRICING_URL).then((data) => {
            setPricingPage(data.data)
            // setStaffingPage(data)
          });
    }, []);

    const handleCardClick = (id) => {
   const Dataid = {
    membership_plan_id : id
   }
               try {
                 PostApiCall(APIConstants.MEMBERSHIP_PLANID, Dataid).then((response) => {
                  if(response.status == true){
                    toast("Redirecting to the payment page");
                    window.location.href = response.data.payment_url
                  }
                 });
                 // Reset form
               } catch (error) {
                 console.error("Error submitting support request:", error);
                 toast("Failed to submit request.");
               }
    }

    return(
        <>
          <div className="container my-5"> 
      {/* Banner Section with Imported Image */}
      <div
        className="text-center text-white rounded"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${Banner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          padding: "80px 20px",
          borderRadius: "10px",
        }}
      >
        {/* <h2 className="fw-bold">The only job board to do all of the work for you. For as little as $129</h2> */}
        <h4 className="fw-light" style={{fontFamily: "Lato"}}>The only job board to do all of the work for you. For as little as $129</h4>
        <button className="btn btn-primary mt-3 px-4 py-2 fw-bold" style={{fontFamily: "Lato"}}>BUY NOW!</button>
      </div>

      {/* Pricing Cards Section */}
      <h3 className="text-center my-5 fw-bold" style={{fontFamily: "Lato"}}>Choose a product</h3>
      <div className="row g-4">
        {/* Standard Job Card */}
        {PricingPage?.map((item, index) => (
          <div className="col-md-4">
          <div className="card text-center p-3 shadow-sm h-100" onClick={() => handleCardClick(item.id)} >
            <h4 className="fw-bold" style={{fontFamily: "Lato"}}>{item?.package_name}</h4>
            <h3 className="text-primary fw-bold" style={{fontFamily: "Lato"}}>${item?.price}</h3>
            <button className="btn fw-bold px-4" style={{fontFamily: "Lato", backgroundColor: "#ffd42f",}}>BUY NOW!</button>
            <ul className="text-start mt-3 px-3" style={{fontFamily: "Lato"}}>
              <li>{item.credit} Job Credit</li>
              <li>Job ad is posted for 30 days</li>
              <li>Branded with your logo</li>
              <li key={index} dangerouslySetInnerHTML={{ __html: item.description }} />
            </ul>
          </div>
        </div>
        )
        )}

        {/* Featured Job Card */}
        {/* <div className="col-md-4">
          <div className="card text-center p-3 shadow-sm h-100">
            <h4 className="fw-bold text-primary" style={{fontFamily: "Lato"}}>Featured Job</h4>
            <h3 className="text-primary fw-bold" style={{fontFamily: "Lato"}}>$100</h3>
            <button className="btn fw-bold px-4" style={{fontFamily: "Lato", backgroundColor: "#ffd42f",}}>BUY NOW!</button>
            <ul className="text-start mt-3 px-3" style={{fontFamily: "Lato"}}>
              <li>3 Job Credits</li>
              <li>Job ad is posted for 30 days</li>
              <li>Branded with your logo</li>
              <li>Your dedicated account manager will: meet with you to discuss the qualifications and experience required, ensure the job description is top-notch, pre-screen candidates, screen candidates via phone, suggest only the top candidates for you to consider</li>
            </ul>
          </div>
        </div> */}

        {/* Spotlight Job Card */}
        {/* <div className="col-md-4">
          <div className="card text-center p-3 shadow-sm h-100">
            <h4 className="fw-bold text-warning" style={{fontFamily: "Lato"}}>Spotlight Job</h4>
            <h3 className="text-primary fw-bold" style={{fontFamily: "Lato"}}>$20</h3>
            <button className="btn fw-bold px-4" style={{fontFamily: "Lato", backgroundColor: "#ffd42f",}}>BUY NOW!</button>
            <ul className="text-start mt-3 px-3" style={{fontFamily: "Lato"}}>
              <li>5 Job Credits</li>
              <li>Job ad is posted for 30 days</li>
              <li>Branded with your logo</li>
              <li>Your dedicated account manager will: meet with you to discuss the qualifications and experience required, ensure the job description is top-notch, pre-screen candidates, screen candidates via phone, suggest only the top candidates for you to consider</li>
            </ul>
          </div>
        </div> */}
      </div>
    </div>
        </>
    )
}

export default PriceingPage;