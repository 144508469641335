import React, { useState } from "react";
import Select, { NonceProvider } from "react-select";
import { PostApiCall } from "../../../utils/axios";
import { APIConstants } from "../../../utils/config";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const JobForm = ({ setShowjobForm }) => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    job_type: "",
    job_location_type: "",
    benefits: "",
    application_deadline: "",
    job_location: "",
    max_salary: "",
    min_salary: "",
    no_of_resource: "",
    expected_start_date: "",
  });
  console.log(formData, "formData");
  const [showPreview, setShowPreview] = useState(false);
  const navigate = useNavigate();

  const numberOfResources = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "More", label: "More" },
  ];

  const benefitsType = [
    { value: "RRSP", label: "RRSP" },
    { value: "Health Benefits", label: "Health Benefits" },
    { value: "Dental Benefits", label: "Dental Benefits" },
    { value: "Vision Benefits", label: "Vision Benefits" },
    { value: "Stock Plan", label: "Stock Plan" },
    { value: "Company Matching", label: "Company Matching" },
  ];

  const AppDeadLines = [
    { value: "7days", label: "7days" },
    { value: "10days", label: "10days" },
    { value: "15days", label: "15days" },
    { value: "20days", label: "20days" },
    { value: "25days", label: "25days" },
    { value: "30days", label: "30days" },
  ];

  const JobType = [
    { value: "Permanent", label: "Permanent" },
    { value: "Contract", label: "Contract" },
    { value: "Full Time", label: "Full Time" },
    { value: "Part Time", label: "Part Time" },
    { value: "Seasonal", label: "Seasonal" },
    { value: "Casual", label: "Casual" },
    { value: "Internship", label: "Internship" },
    { value: "Apprentice", label: "Apprentice" },
    { value: "Volunteer", label: "Volunteer" },
  ];

  const options = [
    { value: "In Person", label: "In Person" },
    { value: "Fully remote", label: "Fully remote" },
    { value: "On the road", label: "On the road" },
    { value: "Hybrid", label: "Hybrid" },
  ];

  const handleChange = (e) => {
    const { name, value, type } = e.target || {};

    setFormData((prevData) => ({
      ...prevData,
      [name]: Array.isArray(value)
        ? value.join(", ") // Convert multi-select array to a comma-separated string
        : String(value), // Convert all other values to a string
    }));
  };

  // Handle Select component separately
  const handleSelectChange = (selectedOption, actionMeta) => {
    setFormData((prevData) => ({
      ...prevData,
      [actionMeta.name]: selectedOption.value, // Store the selected value
    }));
  };

  const handleSelectChanges = (selectedOptions, actionMeta) => {
    const selectedValues = selectedOptions
      .map((option) => option.value)
      .join(", "); // Convert to comma-separated string

    setFormData((prevData) => ({
      ...prevData,
      [actionMeta.name]: selectedValues, // Store as a string
    }));
  };

  const HandleJobPosting = (e) => {
    e.preventDefault();
    try {
      PostApiCall(APIConstants.POST_JOB, formData).then((response) => {
        console.log(response, "response");
        if (response.payment_required == 1) {
          toast("Job creadit is required");
          navigate("/dashboard/pricing-page");
        } else {
          toast(response.message);
          setFormData({
            title: "",
            description: "",
            job_type: "",
            job_location_type: "",
            benefits: "",
            application_deadline: "",
            job_location: "",
            max_salary: "",
            min_salary: "",
            no_of_resource: "",
            expected_start_date: "",
          });
          setShowjobForm(false);
        }
      });
    } catch (error) {
      console.error("Error submitting support request:", error);
      toast("Failed to submit request.");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowPreview(true);
    //   try {
    //     PostApiCall(APIConstants.POST_JOB, formData).then((response) => {
    //       console.log(response, "response");
    //       toast(response.message);
    //       setFormData({
    //   title: "",
    //   description: "",
    //   job_type: "",
    //   job_location_type: "",
    //   benefits: "",
    //   application_deadline: "",
    //   job_location: "",
    //   max_salary: 0,
    //   min_salary: 0,
    //   no_of_resource: "",
    // });
    //       setShowjobForm(false);
    //     });
    //   } catch (error) {
    //     console.error("Error submitting support request:", error);
    //     toast("Failed to submit request.");
    //   }
  };

  return (
    <div
      className="container d-flex justify-content-center align-items-center mb-5"
      style={{ minHeight: "40vh", marginTop: "20px" }}
    >
      <div className="col-md-8 p-4 shadow rounded bg-light">
        {!showPreview ? (
          <>
            <p
              className="text-center text-muted mt-2 mb-4"
              style={{ fontFamily: "Lato" }}
            >
              Please fill out the form to post a job
            </p>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label
                  htmlFor="title"
                  className="form-label"
                  style={{ fontSize: "18px", fontFamily: "Lato" }}
                >
                  Job Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  name="title"
                  placeholder="Enter your job Title"
                  onChange={handleChange}
                  value={formData?.title}
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="no_of_resource"
                  className="form-label"
                  style={{ fontSize: "18px", fontFamily: "Lato" }}
                >
                  Number of people to hire for this job
                </label>
                <Select
                  placeholder="Choose Number of people to hire for this job"
                  name="no_of_resource"
                  options={numberOfResources}
                  onChange={handleSelectChange} // Use handleSelectChange for Select
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="job_location_type"
                  className="form-label"
                  style={{ fontSize: "18px", fontFamily: "Lato" }}
                >
                  Jobs Location Type
                </label>
                <Select
                  placeholder="Choose Job Category"
                  name="job_location_type"
                  options={options}
                  onChange={handleSelectChange} // Use handleSelectChange for Select
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="job_location"
                  className="form-label"
                  style={{ fontSize: "18px", fontFamily: "Lato" }}
                >
                  Address
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="job_location"
                  name="job_location"
                  placeholder="Enter Address"
                  onChange={handleChange}
                  value={formData?.job_location}
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="job_type"
                  className="form-label"
                  style={{ fontSize: "18px", fontFamily: "Lato" }}
                >
                  Job Type
                </label>
                <Select
                  placeholder="Choose The Job Type You Want"
                  name="job_type"
                  options={JobType}
                  onChange={handleSelectChanges} // Use handleSelectChange for Select
                  isMulti // Enables multi-selection
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="min_salary"
                  className="form-label"
                  style={{ fontSize: "18px", fontFamily: "Lato" }}
                >
                  Min Salary
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="min_salary"
                  name="min_salary"
                  placeholder="Enter Minimum Salary"
                  onChange={handleChange}
                  value={formData?.min_salary}
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="max_salary"
                  className="form-label"
                  style={{ fontSize: "18px", fontFamily: "Lato" }}
                >
                  Max Salary
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="max_salary"
                  name="max_salary"
                  placeholder="Enter your Max Salary"
                  onChange={handleChange}
                  value={formData?.max_salary}
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="benefits"
                  className="form-label"
                  style={{ fontSize: "18px", fontFamily: "Lato" }}
                >
                  Benefits
                </label>
                <Select
                  placeholder="Choose The Job Type You Want"
                  name="benefits"
                  options={benefitsType}
                  onChange={handleSelectChanges} // Use handleSelectChange for Select
                  isMulti
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="description"
                  className="form-label"
                  style={{ fontSize: "18px", fontFamily: "Lato" }}
                >
                  Jobs Discription
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  id="description"
                  name="description"
                  placeholder="Enter Job Discription"
                  onChange={handleChange}
                  value={formData?.description}
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="application_deadline"
                  className="form-label"
                  style={{ fontSize: "18px", fontFamily: "Lato" }}
                >
                  Application deadlines
                </label>
                <Select
                  placeholder="Choose The Job Type You Want"
                  name="application_deadline"
                  options={AppDeadLines}
                  onChange={handleSelectChange} // Use handleSelectChange for Select
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="expected_start_date"
                  className="form-label"
                  style={{ fontSize: "18px", fontFamily: "Lato" }}
                >
                  Job Date
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="expected_start_date"
                  name="expected_start_date"
                  onChange={handleChange}
                  value={formData?.expected_start_date || ""}
                />
              </div>
              <button
                type="submit"
                className="btn w-100"
                style={{
                  fontSize: "18px",
                  fontFamily: "Lato",
                  backgroundColor: "#ffd42f",
                  marginBottom: "10px",
                }}
              >
                Submit
              </button>
              <button
                onClick={() => {
                  setShowjobForm(false);
                }}
                className="btn w-100"
                style={{
                  fontSize: "18px",
                  fontFamily: "Lato",
                  backgroundColor: "#020f1e",
                  color: "white",
                }}
              >
                Back
              </button>
            </form>
          </>
        ) : (
          <div className="card shadow-lg p-4">
            <h3 className="text-center mb-4 text-primary fw-bold">
              Job Preview
            </h3>

            <div className="row mb-3">
              <div className="col-md-6">
                <p>
                  <strong>Job Title:</strong> {formData.title}
                </p>
              </div>
              <div className="col-md-6">
                <p>
                  <strong>Number of Applicants:</strong>{" "}
                  {formData.no_of_resource}
                </p>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <p>
                  <strong>Job Location Type:</strong>{" "}
                  {formData.job_location_type}
                </p>
              </div>
              <div className="col-md-6">
                <p>
                  <strong>Address:</strong> {formData.job_location}
                </p>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <p>
                  <strong>Job Type:</strong> {formData.job_type}
                </p>
              </div>
              <div className="col-md-6">
                <p>
                  <strong>Min Salary:</strong> {formData.min_salary}
                </p>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <p>
                  <strong>Max Salary:</strong> {formData.max_salary}
                </p>
              </div>
              <div className="col-md-6">
                <p>
                  <strong>Benefits:</strong> {formData.benefits}
                </p>
              </div>
            </div>

            <div className="mb-3">
              <p>
                <strong>Description:</strong>
              </p>
              <div
                className="border rounded p-2 bg-light"
                style={{ maxHeight: "150px", overflowY: "auto" }}
              >
                {formData.description}
              </div>
            </div>

            <div className="mb-3">
              <p>
                <strong>Application Deadlines:</strong>{" "}
                {formData.application_deadline}
              </p>
            </div>

            <div className="mb-3">
              <p>
                <strong>Expected Start date:</strong>{" "}
                {formData.expected_start_date}
              </p>
            </div>

            <button
              className="btn w-100 mt-3 fw-bold"
              style={{ backgroundColor: "#ffd42f" }}
              onClick={() => setShowPreview(false)}
            >
              Edit Job Details
            </button>

            <button
              className="btn w-100 mt-3 fw-bold"
              style={{ backgroundColor: "#ffd42f" }}
              onClick={HandleJobPosting}
            >
              Post The Job
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default JobForm;
