import React, {useState} from "react";
import { PostApiCall } from "../../utils/axios";
import { APIConstants } from "../../utils/config";
import { toast } from "react-toastify";

const StaffingForm = ({setShowStaffingForm}) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    phone: '',
    position: '',
    resource:'',
  });
  const [errors, setErrors] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false)

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Remove error message when the user types
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

    // Validate form
    const validateForm = () => {
      let newErrors = {};
      if (!formData.name.trim()) newErrors.name = "Name is required";
      if (!formData.email.trim()) newErrors.email = "Email is required";
      else if (!/\S+@\S+\.\S+/.test(formData.email))
        newErrors.email = "Enter a valid email";
      if (!formData.company.trim()) newErrors.company = "Company is required";
      if (!formData.phone.trim()) newErrors.phone = "Phone is required";
      if (!formData.resource.trim()) newErrors.resource = "Resource is required";
      if (!formData.position.trim()) newErrors.position = "Resource is required";
  
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) return; // Stop if validation fails

        try {
          PostApiCall(APIConstants.STAFFING, formData).then((response) => {
            console.log(response, "response");
            toast("Form submitted. We will contact you soon.");
            setFormData({ name: "", email: "", company: "", phone: "", resource: "", position: "" });
            setFormSubmitted(true);
          });
          // Reset form
        } catch (error) {
          console.error("Error submitting support request:", error);
          toast("Failed to submit request.");
        }
  };
  return (
    <>
    {formSubmitted == false ? (
      <div
        className="container d-flex justify-content-center align-items-center mb-5"
        style={{ minHeight: "80vh" }}
      >
        <div className="col-md-8 p-4 shadow rounded bg-light">
          <p
            className="text-center text-muted mt-2 mb-4"
            style={{ fontFamily: "Lato" }}
          >
            Please fill out the form and one of our dedicated Account Managers will be in touch with you regarding your open role
          </p>

          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label
                htmlFor="name"
                className="form-label"
                style={{ fontSize: "18px", fontFamily: "Lato" }}
              >
                Full Name:
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                placeholder="Enter your name"
                value={formData?.name}
                onChange={handleChange}
              />
              {errors.name && (
                <small className="text-danger">{errors?.name}</small>
              )}
            </div>

            <div className="mb-3">
              <label
                htmlFor="email"
                className="form-label"
                style={{ fontSize: "18px", fontFamily: "Lato" }}
              >
                Email Address:
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                placeholder="Enter your email"
                value={formData?.email}
                onChange={handleChange}
              />
              {errors.email && (
                <small className="text-danger">{errors?.email}</small>
              )}
            </div>

            <div className="mb-3">
              <label
                htmlFor="company"
                className="form-label"
                style={{ fontSize: "18px", fontFamily: "Lato" }}
              >
                Company:
              </label>
              <input
                className="form-control"
                id="company"
                name="company"
                rows="4"
                placeholder="Type your message here..."
                value={formData?.company}
                onChange={handleChange}
              />
              {errors.company && (
                <small className="text-danger">{errors?.company}</small>
              )}
            </div>

            <div className="mb-3">
              <label
                htmlFor="phone"
                className="form-label"
                style={{ fontSize: "18px", fontFamily: "Lato" }}
              >
                Phone:
              </label>
              <input
                className="form-control"
                id="phone"
                name="phone"
                rows="4"
                placeholder="Type your message here..."
                value={formData?.phone}
                onChange={handleChange}
              />
              {errors.phone && (
                <small className="text-danger">{errors?.phone}</small>
              )}
            </div>

            <div className="mb-3">
              <label
                htmlFor="position"
                className="form-label"
                style={{ fontSize: "18px", fontFamily: "Lato" }}
              >
                Position:
              </label>
              <input
                className="form-control"
                id="position"
                name="position"
                rows="4"
                placeholder="Type your message here..."
                value={formData?.position}
                onChange={handleChange}
              />
              {errors.position && (
                <small className="text-danger">{errors?.position}</small>
              )}
            </div>

            <div className="mb-3">
              <label
                htmlFor="resource"
                className="form-label"
                style={{ fontSize: "18px", fontFamily: "Lato" }}
              >
                Number of Resources Required
              </label>
              <input
                className="form-control"
                id="resource"
                name="resource"
                rows="4"
                placeholder="Type your message here..."
                value={formData?.resource}
                onChange={handleChange}
              />
              {errors.resource && (
                <small className="text-danger">{errors?.resource}</small>
              )}
            </div>

            <button
              type="submit"
              className="btn w-100"
              style={{
                fontSize: "18px",
                fontFamily: "Lato",
                backgroundColor: "#ffd42f",
                marginBottom: '10px'
              }}
            >
              Submit
            </button>
            <button
            //   type="submit"
            onClick={() => {setShowStaffingForm(false)}}
              className="btn w-100"
              style={{
                fontSize: "18px",
                fontFamily: "Lato",
                backgroundColor: "#020f1e",
                color: 'white',
              }}
            >
              Back
            </button>
          </form>
        </div>
      </div>
    ) : (
      <div
        className="container d-flex justify-content-center align-items-center mb-5"
      >
         <div className="col-md-8 p-4 shadow rounded bg-light">
          <p  className="text-center text-muted mt-2 mb-4"
            style={{ fontFamily: "Lato" }}>
              Form submitted SuccessFully
            </p>
         </div>
      </div>
    )}
    </>
  );
};
export default StaffingForm;
