import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { GetApiCall } from "../../../utils/axios"; // Assuming DeleteApiCall exists
import { APIConstants } from "../../../utils/config";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap
import axios from "axios";

const MyAppliedJobs = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchSavedJobs();
  }, []);

  // Fetch saved jobs from API
  const fetchSavedJobs = () => {
    GetApiCall(APIConstants.APPLIED_JOBS).then((response) => {
      if (response?.data) {
        setData(response.data);
      } else {
        console.error("API response is not an array:", response.data);
      }
    });
  };

  // Handle delete action
  const handleDelete = (id) => {
    try {
      const response = axios.post(
        `https://api.hireboss.ca/api/job/applied-job-delete/${id}`, // Assuming job_id is needed,
      );

      if (response.status == true) {
        setData(data.filter((job) => job.id !== id));
        fetchSavedJobs();
      }
    } catch (error) {
      console.error("Error saving job:", error.response?.data || error.message);
    }
  };

  // Define table columns
  const columns = [
    {
      name: "Company Name",
      selector: (row) => row.company,
      sortable: true,
      style: { fontFamily: "Lato", fontSize: "18px" },
    },
    {
      name: "Job Title",
      selector: (row) => row.title,
      sortable: true,
      style: { fontFamily: "Lato", fontSize: "18px" },
    },
    {
      name: "Actions",
      cell: (row) => (
        <button
          className="btn btn-danger btn-sm"
          onClick={() => handleDelete(row.id)}
        >
          Delete
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <div className="container mt-4">
      <div className="card shadow-lg">
        <div
          className="card-header text-white"
          style={{ backgroundColor: "#ffd42f" }}
        >
          <h4 className="mb-0">Saved Vacancies</h4>
        </div>
        <div className="card-body">
          <DataTable
            columns={columns}
            data={data}
            pagination
            responsive
            className="table-striped table-hover"
          />
        </div>
      </div>
    </div>
  );
};

export default MyAppliedJobs;
