import React from "react";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();
  const CmsHandler = (cmsLink) => {
    navigate(cmsLink);
  };

  const handleClick = (event, location) => {
    event.preventDefault(); // Prevent default link behavior
    if(location == "Remote Jobs"){
      navigate(`/jobs-search?location_type=fully%20remote`);
    }else{
      const formattedLocation = location.replace("Jobs in ", "").trim();
      navigate(`/jobs-search?location=${encodeURIComponent(formattedLocation)}`);
    }
  };


  return (
<footer className="footer-section">
  <div className="container">
    <div className="row">
      {/* Left Column */}
      <div className="col-md-6 d-flex flex-column gap-3">
        {/* Logo */}
        <div className="footer-logo">
          <a href="/">Hireboss</a>
        </div>

        {/* Support Links */}
        <ul className="list-unstyled d-flex gap-4">
          <li>
            <a href="javascript: void(0)" style={{color: '#4e6477'}} onClick={() => CmsHandler("/support")}>
              Support
            </a>
          </li>
          <li>
            <a href="javascript: void(0)" style={{color: '#4e6477'}} onClick={() => CmsHandler("/contact-us")}>
              Contact Us
            </a>
          </li>
        </ul>

        {/* Social Icons */}
        <ul className="list-unstyled d-flex gap-3">
          <li>
            <a
              href="https://www.linkedin.com/company/105622029/admin/analytics/visitors/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="bi bi-linkedin" style={{ fontSize: "1.5rem" }}></i>
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/hireboss.ca/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="bi bi-instagram" style={{ fontSize: "1.5rem" }}></i>
            </a>
          </li>
        </ul>
      </div>

      {/* Right Column - Jobs Section (Two Columns) */}
      <div className="col-md-6">
        <div className="row">
          {[
            "Jobs in Toronto",
            "Jobs in Alberta",
            "Jobs in British Columbia",
            "Jobs in Maritimes",
            "Remote Jobs",
          ].map((job, index) => (
            <div key={index} className="col-6 mb-2">
              <a href="javascript: void(0)" onClick={(e) => handleClick(e, job)}>
                {job}
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
</footer>
  );
}

export default Footer;
