import React, { useContext, useEffect, useState } from "react";
import "./index.css";
import SearchImage from "./image/search.png";
import Correct from "./image/correct.png";
import SavedDoc from "./image/SavedDoc.png";
import RingingBell from "./image/RingingBell.png";
import ResumeDoc from "./image/resumedoc.png";
import Profile from "./image/profile.png";
import LogoutImage from "./image/logout.png";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/auth-context";

const Dashboard = () => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const handleJobSearch = () => {
    navigate("/jobs-search");
  };
  const handleJobAppliedClick = () => {
    navigate("/dashboard-page/my-applied-jobs");
  }
  const handleSavedJobsPage = () => {
    navigate("/dashboard-page/my-saved-job");
  }
  const handlejobAlert = () => {
    navigate("/dashboard-page/my-job-alert");
  }
  const handleResume = () => {
    navigate("/candidates-account/resume");
  };
  const handleAccountSetting = () => {
    navigate("/candidates-account/account");
  };
  const handleLogout = async () => {
    try {
      await auth.signOut();
      localStorage.setItem("firstVisit", "true");
      navigate("/");
      window.location.reload(); // Optional: Reload to reflect logout state
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };
  return (
    <div class="container dashboard-container">
      <h2 className="mb-5">Dashboard</h2>

      {/* <!-- Tabs Navigation --> */}
      <ul class="nav nav-tabs mt-5">
        <li class="nav-item">
          <a class="nav-link active p-3" data-bs-toggle="tab" href="#jobs">
            JOBS
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link p-3" data-bs-toggle="tab" href="#resumes">
            RESUMES
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link p-3" data-bs-toggle="tab" href="#account">
            ACCOUNT INFO
          </a>
        </li>
      </ul>

      <div class="tab-content mt-4">
        {/* <!-- Jobs Section --> */}
        <div class="tab-pane fade show active" id="jobs">
          <div class="row g-4">
            <div class="col-md-3">
              <div class="cardss" onClick={handleJobSearch}>
                <img src={SearchImage} alt="Job Search" class="icon-img" />
                <h5 style={{ color: "#ffd42f", fontFamily: "Lato" }}>
                  Job Search
                </h5>
                <p style={{ fontFamily: "Lato" }}>Advanced job search form</p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="cardss" onClick={handleJobAppliedClick}>
                <img src={Correct} alt="Jobs Applied" class="icon-img" />
                <h5 style={{ color: "#ffd42f", fontFamily: "Lato" }}>
                  Jobs Applied For
                </h5>
                <p style={{ fontFamily: "Lato" }}>
                  Review your past job applications
                </p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="cardss" onClick={handleSavedJobsPage}>
                <img src={SavedDoc} alt="Saved Jobs" class="icon-img" />
                <h5 style={{ color: "#ffd42f", fontFamily: "Lato" }}>
                  Saved Jobs
                </h5>
                <p style={{ fontFamily: "Lato" }}>
                  View and manage your saved jobs
                </p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="cardss" onClick={handlejobAlert}>
                <img src={RingingBell} alt="Job Alerts" class="icon-img" />
                <h5 style={{ color: "#ffd42f", fontFamily: "Lato" }}>
                  Job Alerts
                </h5>
                <p style={{ fontFamily: "Lato" }}>
                  Review and manage your job alerts
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Resumes Section --> */}
        <div class="tab-pane fade" id="resumes">
          <div class="row g-4">
            <div class="col-md-3">
              <div class="cardss" onClick={handleResume}>
                <img src={ResumeDoc} alt="Jobs Applied" class="icon-img" />
                <h5 style={{ color: "#ffd42f", fontFamily: "Lato" }}>
                  Add Resume
                </h5>
                <p style={{ fontFamily: "Lato" }}>
                  Upload your resume to be visible to recruiter.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Account Info Section --> */}
        <div class="tab-pane fade" id="account">
          <div class="row g-4">
            <div class="col-md-3">
              <div class="cardss" onClick={handleAccountSetting}>
                <img src={Profile} alt="Jobs Applied" class="icon-img" />
                <h5 style={{ color: "#ffd42f", fontFamily: "Lato" }}>
                  Change Username & Login
                </h5>
                <p style={{ fontFamily: "Lato" }}>
                  You can always edit your login details and profile.
                </p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="cardss" onClick={handleLogout}>
                <img src={LogoutImage} alt="Jobs Applied" class="icon-img" />
                <h5 style={{ color: "#ffd42f", fontFamily: "Lato" }}>Logout</h5>
                <p style={{ fontFamily: "Lato" }}>Logout from your account</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
