import { useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { UserIdContext } from "../../contexts/auth-context";
import Loader from "../../components/loader";
import Footer from "../../components/footer";
import { CommonActionTypes } from "../../redux/common/common-enum";
import React from "react";
import StaffingForm from "./staffingForm";
import { GetApiCall } from "../../utils/axios";
import { APIConstants } from "../../utils/config";

const Staffing = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [aboutList, setAboutList] = useState([]);
  const userIdContext = useContext(UserIdContext);
  const [showStafingForm, setShowStaffingForm] = useState(false);
  const [staffingPage, setStaffingPage] = useState([]);

  useEffect(() => {
    setLoading(true);
    GetApiCall(APIConstants.CMS_STAFFING_URL).then((data) => {
          console.log(data, "data");
          setLoading(false);
          setStaffingPage(data)
        });
  }, []);

  const signUpClick = () => {
    dispatch({
      type: CommonActionTypes.AUTHENTICATIONSSIGNIN,
      signInPopUp: true,
    });
  };

  return (
    <div class="grey-bg p-cms">
      {loading && <Loader loading={loading} />}
      {showStafingForm == false ? (
        <div class="candidate-outer ">
        <div class="container-cstm">
          <div class="content-inner">
            <div class="cmp-desg-apply-section">
              <div class="job-desc text-center" style={{ fontFamily: "Lato" }}>
                {/* <p>"Connecting the right people to the right jobs"</p> */}
                {staffingPage?.data?.map((item, index) => (
                  <p key={index} dangerouslySetInnerHTML={{ __html: item.title }}>
                </p>
                ))}
                {staffingPage?.data?.map((item, index) => (
                  <p className="mt-5" key={index} dangerouslySetInnerHTML={{ __html: item.body }}>
                </p>
                ))}
                <div className="flex mt-5">
                  <button
                    style={{
                      marginRight: "20px",
                      padding: "15px",
                      fontSize: "18px",
                      fontFamily: "Lato",
                      backgroundColor: "#ffd42f",
                      borderColor: "transparent"
                    }}
                    onClick={() => signUpClick()}
                  >
                    I am a Jobseeker
                  </button>
                  <button
                    style={{
                      marginLeft: "20px",
                      padding: "15px",
                      fontSize: "18px",
                      fontFamily: "Lato",
                      backgroundColor: "#ffd42f",
                      borderColor: "transparent"
                    }}
                    onClick={() => setShowStaffingForm(true)}
                  >
                    I am a company looking to hire
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      ) : (
        <StaffingForm setShowStaffingForm={setShowStaffingForm} />
      )}
      <Footer />
    </div>
  );
};
export default Staffing;
