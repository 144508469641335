import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { GetApiCall } from "../../utils/axios";
import { APIConstants } from "../../utils/config";
// import { PostApiCall } from "../../../utils/axios";
import { PostApiCall } from "../../utils/axios";
import Loader from "../../components/loader";
import Footer from "../../components/footer";
import React from "react";
import { toast } from "react-toastify";

const Support = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    inquiry: "",
    type: 2,
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Remove error message when the user types
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  // Validate form
  const validateForm = () => {
    let newErrors = {};
    if (!formData.name.trim()) newErrors.name = "Name is required";
    if (!formData.email.trim()) newErrors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = "Enter a valid email";
    if (!formData.inquiry.trim()) newErrors.inquiry = "Inquiry is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) return; // Stop if validation fails

    setLoading(true);
    try {
      PostApiCall(APIConstants.SUPPORT_FORM, formData).then((response) => {
        console.log(response, "response");
        toast("Form submitted. We will contact you soon.");
        setFormData({ name: "", email: "", inquiry: "" });
      });
      // Reset form
    } catch (error) {
      console.error("Error submitting support request:", error);
      toast("Failed to submit request.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="grey-bg p-cms">
      {loading && <Loader loading={loading} />}

      <div className="container d-flex justify-content-center align-items-center" style={{minHeight: '80vh'}}>
        <div className="col-md-8 p-4 shadow rounded bg-light">
         <h2 className="text-center" style={{marginBottom: '10px', fontFamily: 'Lato', fontWeight: "600"}}>Support</h2>
          <h2 className="text-center" style={{ fontFamily: "Lato" }}>
            Need assistance? We're happy to help. 24/7.
          </h2>
          <p
            className="text-center text-muted mt-2"
            style={{ fontFamily: "Lato" }}
          >
            Fill out the form to connect with one of our agents
          </p>

          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label
                htmlFor="name"
                className="form-label"
                style={{ fontSize: "18px", fontFamily: "Lato" }}
              >
                Full Name:
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                placeholder="Enter your name"
                value={formData?.name}
                onChange={handleChange}
              />
              {errors.name && (
                <small className="text-danger">{errors?.name}</small>
              )}
            </div>

            <div className="mb-3">
              <label
                htmlFor="email"
                className="form-label"
                style={{ fontSize: "18px", fontFamily: "Lato" }}
              >
                Email Address:
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                placeholder="Enter your email"
                value={formData?.email}
                onChange={handleChange}
              />
              {errors.email && (
                <small className="text-danger">{errors?.email}</small>
              )}
            </div>

            <div className="mb-3">
              <label
                htmlFor="inquiry"
                className="form-label"
                style={{ fontSize: "18px", fontFamily: "Lato" }}
              >
                Inquiry:
              </label>
              <textarea
                className="form-control"
                id="inquiry"
                name="inquiry"
                rows="4"
                placeholder="Type your message here..."
                value={formData?.inquiry}
                onChange={handleChange}
              />
              {errors.inquiry && (
                <small className="text-danger">{errors?.inquiry}</small>
              )}
            </div>

            <button
              type="submit"
              className="btn w-100"
              style={{
                fontSize: "18px",
                fontFamily: "Lato",
                backgroundColor: "#ffd42f",
              }}
            >
              Submit
            </button>
          </form>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Support;
